.tour-item {
    display: block;
    margin-bottom: 20px;
    background-color: #fff;

    &:hover {
        text-decoration: none;

        .ti-header {

            &:after {
                @include square(100%);
            }
            > i {
               bottom: 0;
            }
        }
    }
}
.transfert-item {
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
  background-color: #fff;

  &:hover {
    text-decoration: none;

    .ti-header {

      &:after {
        @include square(100%);
      }
      > i {
        bottom: 0;
      }
    }
  }
}
.ti-header {
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(#000, .46);
        @include square(0);
    }
     >i {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: -200%;
        margin: auto;
        text-align: center;
        @include transition(all .3s ease);
    }

}
.ti-body {
    padding: 15px 10px;
    border: 1px solid #e0e0e0;
}
.tib-title {
    min-height:40px;
    margin: 0;
    color: #4f4f4f;
    font-size: 19px;
    font-weight: bold;
}
.tib-meta {
    color: #4f4f4f;
    margin-top: 0;

    .price {
        font-size: 23px;
        color: $brand-info;
        font-weight: bold;
    }
    .price-info {
        font-size: 14px;
    }
    .media-right {
        min-width: 60px;
        text-align: right;
        vertical-align: bottom;

        i {
            vertical-align: top;
        }
    }
    .time {
        display: inline-block;
        margin-top: 1px;
        font-size: 15px;
    }
}


@media(max-width: $screen-lg-desktop) {

    .tour-item {

    }
}
