#header {

  > nav {
    background-color: transparent;
  }
  .navbar {
    margin-bottom: 0;
  }

  /*
     Header top
     ========================================================================== */

  .navbar-header {
    float: none;
  }
  .header-top {
    background-color: #fff;

    .navbar-nav {
      display: inline-block;
      float: right;
      //            width: 276px;

      > li {
        display: inline-block;

        > a {
          padding-right: 10px;
          padding-left: 10px;
          color: $brand-info;
          &.tel {
            color: black;
            text-decoration: none;
            cursor: default;
            &:hover, &:focus {
              text-decoration: none;
            }
          }
          &:hover, &:focus {
            background-color: #fff;
            text-decoration: underline;
          }
        }
        &.lang {

          a {
            padding-right: 5px;
            padding-left: 5px;
          }
          a:hover {
            @include opacity(.6);
          }
        }

      }
    }
  }

  .brand {
    float: left;
    display: inline-block;
    margin-top: 18px;
  }
  .h-rounded {
    display: inline-block;
    text-align: center;

    img {
      display: inline-block;
    }
    span {
      display: block;
    }

    a {
      font-size: 11px;
      text-transform: uppercase;
    }
  }

  /*
     Header nav
     ========================================================================== */

  .header-nav {
    border: 0;
    position: relative;

    &:before {
      @include size(100%, 7px);
      position: absolute;
      left: 0;
      right: 0;
      background-color: #fff;
    }

    .navbar-nav {
      .sub-arrow {
        right: -10px !important;
      }

      > li {
        text-transform: uppercase;

        &.home, &.social {

          a:hover, a:focus {
            background-color: transparent;
          }
        }
        a:hover {

          .icons-home-gra-s {
            @extend .icons-home-blu-s;
          }
          .icons-fb {
            @extend .icons-fb-blu;
          }
          .icons-tt {
            @extend .icons-tt-blu;
          }
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      padding: 0;
      border: 0;
      background-color: #efefef;

      > li {

        > a {
          padding: 10px 20px;
          border-bottom: 1px solid #cecece;

          &:hover {
            background-color: $brand-info;
            color: #fff;
            border-color: #015284;
          }
        }
        &:first-child > a {

        }
      }
    }

  }

  @media(min-width: $grid-float-breakpoint-max) {

    .navbar-right {
      margin-right: 0;
    }
    .header-nav {

      .navbar-left {
        margin-left: 1px;
      }
      .navbar-right {
        margin-right: 1px;
      }

      &.navbar-default {
        background: transparent 0 7px image-url('header-nav-repeat.gif') repeat-x;
      }
      .navbar-nav {

        > li {

          > a {
            height: 61px;
            padding-top: 20px;
            padding-bottom: 20px;
          }

        }
      }
    }
    .header-top {

      .navbar-nav {

        > li {

          &.lang {

            a {
              padding-top: 20px;
              padding-bottom: 41px;
            }
          }
          &.en {
            margin-left: 15px;
          }

        }
      }
    }
  }

  @media(max-width: $grid-float-breakpoint-max) {

    .brand {
      margin-left: 5px;
    }
    .h-rounded {

      span {
        display: none;
      }
    }
    .header-top {

      .navbar-nav {
        margin-right: 15px;
        margin-left: 0;
      }
    }
  }
  @media(max-width: $screen-tablet) {

    .header-top {

      .navbar-nav {
        margin-right: 5px;

        > li > a {
          padding-right: 3px !important;
          padding-left: 3px !important;
        }
      }
    }
  }
  @media(max-width: $screen-phone) {

    .header-top {

      .navbar-nav {
        margin-right: 0;
        float: none;
        width: 100%;
        text-align: center;
      }
    }
  }

}

/*
   Collpase
   ========================================================================== */

.navbar-collapse {
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-default .navbar-toggle {
  margin: 18px 5px 0;

  &:hover, &:focus {

    .icon-bar {
      background-color: #fff;
    }
  }

  .sub-arrow {
    right: -10px !important;
  }
}
