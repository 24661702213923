#footer {
    //margin-top: 15px;

    .footer-wr {
        padding: 15px 10px;
        background-color: #fff;

        .row {
            overflow: hidden;

            > div:first-child .footer-item:before {
                 content: none;
            }
        }
    }

    &, a {
        color: #828282;
    }
    .fl-title {
        margin: 0 0 15px;
        color: $brand-info;
        font-size: 19px;
        text-transform: uppercase;
    }

    .footer-item {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 0;
            bottom: 0;
            @include size(1px, 300px);
            background-color: #4f4f4f;
        }
    }

    .footer-download {
        padding: 23px 20px;
        background-color: $brand-info;
        color: #fff;

        &.logged-pro{
            background-color: #2D2D2D;
        }

        span {
            font-size: 19px;
            margin: 0 15px 0 10px;
            vertical-align: middle;

            em {
                font-size: 13px;
            }
        }
        .fd-coutries i {
            margin: 0 2px;
        }
    }

    @media(max-width: $screen-tablet) {

        .footer-item {
            margin-top: 10px;
        }
        .footer-download {
            text-align: center;
        }
        .fd-coutries {
            display: block;
            margin-top: 5px !important;
        }
    }
}
