/*
   Border title
   ========================================================================== */

.border-title {
    margin: 15px 0;
    overflow: hidden;

    h3 {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 5px 15px 5px 5px;
        @include qsand();
        font-size: 30px;
        text-transform: uppercase;

        &:before {
            content: '';
            @include size($container-large-desktop,1px);
            position: absolute;
            top: 50%;
            background-color: #cacaca;
            right: -$container-large-desktop;
        }
    }

    i {
        margin: -11px 8px 0 0;
    }

}


/*
   Header title
   ========================================================================== */

.header-title {
    position: relative;

    //&:after {
    //    content: '';
    //    position: absolute;
    //    left: 0;
    //    bottom: 0;
    //    @include size(100%, 208px);
    //    background: image-url('product/bg-repeat.png') repeat-x;
    //}
   &:not(.no-after) > div:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 208px;
        background: image-url('product/bg-repeat.png') repeat-x;
        z-index: 0;
    }
    &.has-margin-b {
        margin-bottom: 28px;
    }

    .h-title {
        position: absolute;
        z-index: 1;
        bottom: 20px;
        font-size: 52px;
        color: #fff;
        text-shadow: 3px 3px 3px #160f02;

        div {
            color: #fff;
        }
    }
}

.bxslider-tour > div:after
{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 208px;
    background: image-url('product/bg-repeat.png') repeat-x;
    z-index: 0;
}

/*
   Primary title
   ========================================================================== */

.primary-title {
    color: $brand-primary !important;
    
    &.bottom-border {
        border-bottom: 2px solid #de6281;
    }
}
.default-title {
    color: #979797;

    &.bottom-border {
        border-bottom: 2px solid #979797;
    }
}

.bottom-border {    
    padding: 0 0 10px 5px;
}

/*
   FAQ
   ========================================================================== */

.faq {

    h2 {
        margin-bottom: 25px;
    }
    h4 {
        margin: 25px 0 0;
        font-weight: bold;
    }
    .text-info {
        margin-bottom: 35px;
    }
}

.ui-datepicker{
  z-index: 30000!important;
}

/*
   Responsive
   ========================================================================== */

.header-title {

    @media(max-width: $screen-phone) {

        &:after {
            height: 100px;
        }
        .h-title {
            font-size: 18px;

            .h3 {
                font-size: 16px;
            }
        }
        & > div:after {
            content: none;
        }
    }
    @media(max-width: $screen-phone-xs) {


    }
}
