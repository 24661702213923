.icons- {
    display: inline-block;
    vertical-align: middle;
    background-image: url('/bundles/app/images/icons-gnf4jmysisj.png');
    background-repeat: no-repeat;
}

.icons-adult-whi-m {
    @extend .icons-;
    width: 20px;
    height: 33px;
    background-position: -213px 0px;
}
.icons-audioguide {
    @extend .icons-;
    width: 55px;
    height: 55px;
    background-position: -57px 0px;
}
.icons-basse {
    @extend .icons-;
    width: 55px;
    height: 55px;
    background-position: 0px -57px;
}
.icons-billeterie {
    @extend .icons-;
    width: 55px;
    height: 55px;
    background-position: -114px -57px;
}
.icons-br {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: -34px -154px;
}
.icons-calendar-bla-s {
    @extend .icons-;
    width: 14px;
    height: 16px;
    background-position: -153px -114px;
}
.icons-calendar-gra-s {
    @extend .icons-;
    width: 19px;
    height: 19px;
    background-position: -213px -35px;
}
.icons-calendar2-bla-s {
    @extend .icons-;
    width: 15px;
    height: 15px;
    background-position: -213px -175px;
}
.icons-car-whi-m {
    @extend .icons-;
    width: 30px;
    height: 14px;
    background-position: -137px -181px;
}
.icons-childs-whi-m {
    @extend .icons-;
    width: 25px;
    height: 22px;
    background-position: -110px -181px;
}
.icons-chronometer-gra-s {
    @extend .icons-;
    width: 16px;
    height: 20px;
    background-position: -213px -113px;
}
.icons-cold-whi-m {
    @extend .icons-;
    width: 34px;
    height: 34px;
    background-position: -79px -114px;
}
.icons-de {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: -170px -154px;
}
.icons-download-whi-m {
    @extend .icons-;
    width: 25px;
    height: 25px;
    background-position: -56px -181px;
}
.icons-en {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: -171px -91px;
}
.icons-es {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: -171px -118px;
}
.icons-fb-blu {
    @extend .icons-;
    width: 10px;
    height: 19px;
    background-position: -198px -56px;
}
.icons-fb-whi {
    @extend .icons-;
    width: 10px;
    height: 19px;
    background-position: -235px 0px;
}
.icons-fb {
    @extend .icons-;
    width: 10px;
    height: 19px;
    background-position: -235px -21px;
}
.icons-flower-whi-m {
    @extend .icons-;
    width: 36px;
    height: 37px;
    background-position: -41px -114px;
}
.icons-fr {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: -136px -154px;
}
.icons-haute {
    @extend .icons-;
    width: 55px;
    height: 55px;
    background-position: -57px -57px;
}
.icons-home-blu-s {
    @extend .icons-;
    width: 15px;
    height: 13px;
    background-position: -186px -181px;
}
.icons-home-gra-s {
    @extend .icons-;
    width: 15px;
    height: 13px;
    background-position: -213px -192px;
}
.icons-home-whi-s {
    @extend .icons-;
    width: 15px;
    height: 13px;
    background-position: -169px -181px;
}
.icons-it {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: -68px -154px;
}
.icons-lang-en {
    @extend .icons-;
    width: 25px;
    height: 25px;
    background-position: -83px -181px;
}
.icons-lang-fr {
    @extend .icons-;
    width: 25px;
    height: 25px;
    background-position: -29px -181px;
}
.icons-leaf-whi-m {
    @extend .icons-;
    width: 25px;
    height: 33px;
    background-position: -171px -56px;
}
.icons-lock-blu-s {
    @extend .icons-;
    width: 15px;
    height: 19px;
    background-position: -213px -135px;
}
.icons-po {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: 0px -154px;
}
.icons-private-whi-m {
    @extend .icons-;
    width: 40px;
    height: 25px;
    background-position: -171px 0px;
}
.icons-private {
    @extend .icons-;
    width: 55px;
    height: 55px;
    background-position: -114px 0px;
}
.icons-repas {
    @extend .icons-;
    width: 55px;
    height: 55px;
    background-position: 0px 0px;
}
.icons-search-whi-m {
    @extend .icons-;
    width: 36px;
    height: 30px;
    background-position: -115px -114px;
}
.icons-search-whi-s {
    @extend .icons-;
    width: 21px;
    height: 17px;
    background-position: -24px -209px;
}
.icons-shopping-cart-gra-m {
    @extend .icons-;
    width: 32px;
    height: 27px;
    background-position: -171px -27px;
}
.icons-shopping-cart-gra-s {
    @extend .icons-;
    width: 22px;
    height: 19px;
    background-position: 0px -209px;
}
.icons-star-pin-m {
    @extend .icons-;
    width: 27px;
    height: 26px;
    background-position: 0px -181px;
}
.icons-sun-whi-m {
    @extend .icons-;
    width: 39px;
    height: 38px;
    background-position: 0px -114px;
}
.icons-trash-bla-m {
    @extend .icons-;
    width: 14px;
    height: 17px;
    background-position: -213px -156px;
}
.icons-tt-blu {
    @extend .icons-;
    width: 19px;
    height: 17px;
    background-position: -213px -94px;
}
.icons-tt-whi {
    @extend .icons-;
    width: 19px;
    height: 17px;
    background-position: -213px -75px;
}
.icons-tt {
    @extend .icons-;
    width: 19px;
    height: 17px;
    background-position: -213px -56px;
}
.icons-us {
    @extend .icons-;
    width: 32px;
    height: 25px;
    background-position: -102px -154px;
}
