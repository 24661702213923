/*
   Product informations
   ========================================================================== */
.header-title-img {
  max-height: 630px;
}

.product-infos {
  position: relative;

  .rounded {
    position: relative;
    @include square(55px);

    &.green {
      background-color: #00a258;
    }
    &.yellow {
      background-color: #f9bc13;
    }
    &.orange {
      background-color: #e08827;
    }
    &.blue {
      background-color: #66c2df;
    }
  }

}
.pi-infos{
  min-height:426px;
}
.pi-description {
  min-height: 297px;
  p {
    font-size: 16px;
  }
}
.pi-infos, .pi-description {
  padding: 15px 0 20px;
}

.product-infos .rounded,
.pi-price, .pi-detail {
  display: inline-block;
}
.pi-price{
  text-align: center;
}
.pi-price, .pi-detail {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
}

.pi-title {
  margin: 0 0 15px;
}

.pi-price {

  .rounded {
    background-color: #bdbdbd;
  }
}

.pi-text {
  font-size: 12px;
}



/*
   Responsive
   ========================================================================== */

@media(min-width: $screen-phone) {

  .pi-price {
    height: 94px;
  }
}

@media(max-width: $screen-phone) {

  .header-title {
    font-size: 40px;
  }

}
