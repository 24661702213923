//== text

.bold {
    font-weight: bold;
}

.regular {
    font-weight: normal;
}

.fw-400 {
    font-weight: 400;
}

.uppercase {
    text-transform: uppercase;
}
.lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.line-through {
    text-decoration: line-through;
}

@mixin text-align($align, $column) {

    .text-#{$align}-#{$column} {

        @if $column == lg {

            @media(min-width: $screen-lg-desktop) {
                text-align: $align;
            }
        }
        @if $column == md {

            @media(min-width: $screen-desktop) {
                text-align: $align;
            }
        }
        @if $column == sm {

            @media(min-width: $screen-tablet) {
                text-align: $align;
            }
        }
        @if $column == xs {

            @media(min-width: $screen-phone) {
                text-align: $align;
            }
        }
    }

}

@include text-align(right, lg);
@include text-align(right, md);
@include text-align(right, sm);
@include text-align(right, xs);

@include text-align(center, lg);
@include text-align(center, md);
@include text-align(center, sm);
@include text-align(center, xs);



//== borders
.rounded, {
    border-radius: 9999px;
}

.s-rounded {
    img, canvas {
        border-radius: 9999px;
    }
}


//== positions
.abs-center {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
}


//== blocs
.directions {
    clear: both;
    @include clearfix();

    > * {
        display: inline-block;
    }

    .left {
        float: left;
    }
    .right {
        float: right;
    }
}

@mixin no-gutter-childrens($selector, $row: false) {

    > [class^="#{$selector}"] {
        padding-right: 0;
        padding-left: 0;
    }
    @if ($row) {
        margin-right: 0;
        margin-left: 0;
    }
}

.no-gutter-child-lg-row {
    @include no-gutter-childrens('col-lg-', true);
}

.no-gutter-child-md-row {
    @include no-gutter-childrens('col-md-', true);
}

.no-gutter-child-sm-row {
    @include no-gutter-childrens('col-sm-', true);
}

.no-gutter-child-lg {
    @include no-gutter-childrens('col-lg-');
}

.no-gutter-child-md {
    @include no-gutter-childrens('col-md-');
}

.no-gutter-child-sm {
    @include no-gutter-childrens('col-sm-');
}

.full-width {
    width: 100%;
}

.full-width-lg {

    @media(min-width: $screen-lg-desktop) {
        width: 100%;
    }
}

.full-width-md {

    @media(min-width: $screen-desktop) {
        width: 100%;
    }
}

.full-width-sm {

    @media(min-width: $screen-tablet) {
        width: 100%;
    }
}

.full-width-xs {

    @media(min-width: $screen-phone) {
        width: 100%;
    }
}

.container-reset {
    padding-left: 0;
    padding-right: 0;
}

.empathise {
    margin: 15px 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.pl-0 {
    padding-left: 0 !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}

hr.medium {
    margin-top: $line-height-computed / 2;
    margin-bottom: $line-height-computed / 2;
}

hr.small {
    margin-top: $line-height-computed / 3;
    margin-bottom: $line-height-computed / 3;
}

.icon-mr {
    margin-right: 5px;
}
.icon-mr-m {
    margin-right: 10px;
}

.icon-ml {
    margin-left: 5px;
}
.icon-ml-m {
    margin-left: 10px;
}

.icon-mmt {
    margin-top: -3px;
}

.media-is-responsive {

    @media(max-width: $screen-phone) {

        .media-object {
            margin-bottom: 15px;
            float: none !important;
            text-align: center;
        }
    }

}

.no-border-top {
    border-top: 0 !important;
}

.no-border-bottom {
    border-bottom: 0;
}


//== vertical align
.va-base {
    vertical-align: baseline;
}

.child-va-top > * {
    vertical-align: top;
    line-height: 1em;
}

.child-va-middle > * {
    vertical-align: middle;
    line-height: 1em;
}

.child-va-bottom > * {
    vertical-align: bottom;
    line-height: 1em;
}

.child-va-baseline > * {
    vertical-align: baseline;
    line-height: 1em;
}

.va-bottom {
    vertical-align: bottom;
}

.va-top {
    vertical-align: top;
}



//== backgrounds
.cover {
    background-size: cover;
}



//== responsive helpers
.breakpoint-show {
    display: none;
}
.breakpoint-hide {

}
@media(max-width: $grid-float-breakpoint-max) {

    .breakpoint-show {
        display: inline-block;
    }
    .breakpoint-hide {
        display: none !important;
    }

}
