/* ==========================================================================
   LAYOUT :: common
   ========================================================================== */

html, body {
    height: 100%;
}
#main {
    margin-top: -1px;
}


/*
   Helpers
   ========================================================================== */

.bg-primary {
    background-color: $brand-primary;
}
.bg-white {
    background-color: #fff;

    &.wrap {
        padding: 10px 0 15px;
    }    
}
.bg-gray {
    background-color: #e3e3e3;
}
.bg-pattern {
    background: 0 -20px image-url('map.gif') repeat;
}
.wrap-content {
    padding: 10px;
}


/* 
   Tooltip
   ========================================================================== */

.tooltip-inner {
    padding: 8px 20px;
    border-radius: 4px;
}




