//== image functions
@function image-url($string) {
    @return url('#{$img-path}#{$string}');
}

@function inline-image($name) {
    @return url('#{$name}');
}

//== clearfix from compass helpers
@mixin pie-clearfix() {
    *zoom: 1;

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

//== Placeholder text from bootstrap mixins
@mixin placeholder_style($weight, $style) {
    &::-moz-placeholder {
        font-weight: $weight;
        font-style:  $style;
    } // Firefox
    &:-ms-input-placeholder {
        font-weight: $weight;
        font-style:  $style;
    } // Internet Explorer 10+
    &::-webkit-input-placeholder {
        font-weight: $weight;
        font-style:  $style;
    } // Safari and Chrome
}
