.btn-transparent {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;

    &:hover {
        background-color: #fff;
        color: $brand-info !important;
    }
}

.no-btn {
    background-color: transparent;
}

.btn-primary {
    border-color: $brand-primary;
}


.btn-send {
    min-width: 273px;
    margin-bottom: 35px;
}
