/* ==========================================================================
   LAYOUT
   ========================================================================== */

.modal {

}

.modal-header {
    position: relative;
    padding: 0 0 15px;
    border-bottom: 0;   
    text-align: center;    

    h4 {        
       font-size: 19px;
    }
    .close {
        float: none;
        position: absolute;
        top: -3px;
        right: 3px;
        //margin: -6px -1px 0 0;
        //padding: 0 0 0 3px;       
        color: #000;
        text-shadow: none;
        @include opacity(1);

        span {
            font-size: 33px;
        }

        &:hover span {
            @include opacity(.7);
        }
    }
    
}

.modal-content {
    border: 10px solid rgba(#000, .6);
    padding: 8px;
    border-radius: 0;
}

.modal-footer {
    border-top: 0;
}




/* ==========================================================================
   MODALS
   ========================================================================== */


/* 
   Modal cart
   ========================================================================== */

#modal-cart {

    .media-body {
        padding: 10px;
        border: 1px solid #9a9a9a;
        font-size: 17px;
        vertical-align: middle;
    }
    .media-left {
        padding-right: 0;
    }
    .modal-footer {
        
        .btn {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    @media(max-width: $screen-phone) {

        .media-left, .media-body {
            display: block;
        }
        .media-left {
            text-align: center;
        }
        .media-body {
            width: 100%;
            margin-top: 10px;
        }
        
    }
    
}


/* 
   Modal basket paiement
   ========================================================================== */

#modal-basket-paiement {
    
    .modal-footer {
        
        .text-center {
            line-height: 1;
        }
        .text-info {
            font-size: 15px;
            vertical-align: middle;
            margin-left: 5px;
        }
    }
}
.card-type {
    display: inline-block;
    width: 160px;
    margin: 0 3px 10px;
    padding: 15px 25px;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    text-align: center;
}