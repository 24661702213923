/*
   Common
   ========================================================================== */

.form-control {
    color: #000000 !important;
    @include box-shadow(none);
}

::-webkit-input-placeholder { color:#696969; }
::-moz-placeholder { color:#696969; } /* firefox 19+ */
:-ms-input-placeholder { color:#696969; } /* ie */
input:-moz-placeholder { color:#696969; }

.input-group.date {

    .form-control {
        border-right: 0;
    }
}
.form-group-l {
    margin-bottom: 35px;
}
.input-lg {
    font-size: $font-size-base;
}

.form-control-italic {
    
    .form-control {
        font-style: italic;
    }
}



/*
   Transfers booking
   ========================================================================== */

.transfers-booking {
    padding: 15px 15px 25px;
    background-color: $brand-info;

    h3 {
        margin: 0 0 27px;
        font-size: 19px;
        line-height: 1em;
        color: #fff;

        span {
            display: inline-block;
            vertical-align: middle;
        }
        .rounded {
            position: relative;
            @include square(41px);
            border: 1px solid #fff;
        }
    }
    .form-control {
        border: 0;
        height: 41px;
    }
    .form-group {
        margin-bottom: 10px;
    }    
}


/*
   Looking tour
   ========================================================================== */

.looking-tour {
    padding: 20px 15px;
    margin-top: 15px;

    &.logged-pro{
        background-color: #2D2D2D;
    }

    .lt-label {
        margin-right: 10px;
        font-size: 26px;
    }

    .date .form-control {
        height: 41px;
        @include border-left-radius(5px);
        border: 0;
        font-size: 19px;
    }
    .input-group-addon {
        @include border-right-radius(5px);
    }
    .btn {        
        color: #fff;
        font-size: 19px;

        &:hover {
            background-color: #d71a49;
            border-radius: 5px;
            border: 1px solid #e3849c;
        }
    }
}


/*
   Quick booking
   ========================================================================== */

// $input-border: #c4c4c4;
// $input-color: #505050;

.quick-booking {
    //margin-top: -420px;

    .form-control {
        color: $text-color;
        @include placeholder($text-color);
    }
}
.qb-header {
    padding: 10px 15px;
    background-color: #474747;
    background-color: rgba(#474747, .76);
    font-size: 26px;
    color: #fff;

    .media-right {
        min-width: 160px;
        text-align: right;
    }
}
.qc-from {

}
.qb-nb {

}

.qb-body {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #a7a7a7;

    .media-body {
        vertical-align: middle;

    }
    .form-inline {
        @include clearfix();
    }
    .form-group-input {
        float: right;
    }
    .form-group-label {
        float: left;
        margin-right: 25px;
        font-size: 18px;
        font-weight: normal;
    }
    .form-group-radio {
        margin-left: 60px;

        .radio {
            margin-right: 25px;
            font-size: 13px;

            span {
                margin-top: -4px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .form-group-select {

        .form-control {
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
    .qb-s1, .qb-s2 {

        .form-control {
            min-width: 240px;
        }
    }
    .qb-s1, .qb-s2 {

        .input-group-addon {
            border: 1px solid $input-border;
            border-left: 0;
        }
    }
}
.qb-title {
    color: $brand-info;
    font-size: 26px;
}
.qb-steps {

    .media-left {
        min-width: 70px;
    }
}
.qb-step {
    @include square(46px);
    text-align: center;
    background-color: $brand-info;
    color: #fff;
    font-size: 21px;
    line-height: 45px;
}
.qb-submit {
    //padding-top: 15px;
    font-size: 21px;
}


/*
   Main search
   ========================================================================== */

.main-search {
    margin: 15px 0 10px;
    padding: 12px;
    border-top: 4px solid $brand-primary;



    .form-control {
        border: 0;
        height: 53px;
        @include placeholder($brand-info);
        font-style: italic;
    }
    .btn {
        padding: 16px;
    }

    &.logged-pro{
        border-top: 4px solid #2D2D2D;
        .btn{
            background-color: #2D2D2D;
            border: none;
        }
    }
}


/*
   Account :: login, password, create
   ========================================================================== */
    
.form-login,
.form-password,
.form-create {
    
    label.italic {
        font-size: 12px;
        margin-bottom: 15px;
    }
}
.form-login {
    
    .btn {
        min-width: 136px;
    }
}
.form-password {
    margin-top: 46px;
}
.form-create {
    margin-bottom: 146px;        
}


/*
   Cart :: basket
   ========================================================================== */

.fcb-title {
   font-size: 16px;
   
   strong {
       display: block;
   }
   em {
       color: $brand-info;
       font-size: 14px;
   }
}
.fcb-description {

}
.fcb-price {
   font-size: 16px;
}
.fcb-trash {
   
   i {
       margin-top: -5px;
   }
}
.fcb-contact {
   font-size: 11px;
}
.fcb-aggregate {
   font-size: 16px;
   color: #4f4f4f;
}
.fcb-aggregate-price {
   margin-left: 35px;
   color: #4f4f4f;
   font-size: 20px;
}
.fcb-condition {
   margin-bottom: 30px;
    
    .tooltip-wr {
        position: relative;
    }
    .tooltip {
        margin-left: -14px;
    }
   .checkbox, .btn {
       font-size: 16px;
   }
   .checkbox {
       margin-bottom: 20px;
   }
   .btn {
       padding-top: 15px;
       padding-bottom: 15px;
       min-width: 230px;
   }
   .btn-primary {
       margin-left: 10px;
   }
}

.form-cart-basket {

    .table {
        margin-bottom: 0;
        color: #4f4f4f;
    }
    thead th {                        

        &.fcb-title {
            font-size: 22px;
        }
    }
    thead th, td:before {
        font-family: 'Quicksand';
        font-size: 16px;
        color: $brand-info;
    }
    thead > tr > th {
        border-bottom: 2px solid #de6281;
    }
    .table tbody > tr > td {
        padding: 15px 8px;
        vertical-align: middle;
        
        &.fcb-img {
            padding-left: 0;
        }
    }
    .tfoot {
        padding-top: 15px;
        border-top: 2px solid #de6281;
    }

    @media (max-width: $screen-desktop) {
       
        table, 
        thead, 
        tbody, 
        th, 
        td, 
        tr { 
            display: block; 
        }         
       
        thead tr { 
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        
        tbody {

            tr {
                padding-top: 15px;
                border-top: 2px solid #de6281;

                &:first-child {
                    border: 0;
                }
            }
        }
     
        td {               
            position: relative;
            border: 0 !important;
            padding: 0 8px 15px 110px !important; 
            white-space: normal;
            text-align: left;

            &:before { 
                content: attr(data-title);
               
                position: absolute;
                top: -2px;
                left: 6px;
                width: 45%; 
                padding-right: 10px; 
                white-space: nowrap;
                text-align:left;
                font-weight: bold;
            }
        }
        .btn.form-group {
            margin-bottom: 15px;
        }
        
    }
}



/*
   Responsive
   ========================================================================== */

@media (min-width: $screen-desktop) and (max-width: $screen-lg-desktop) {

    //.quick-booking {
    //    margin-top: -335px;
    //}
}

@media (min-width: $screen-tablet) and (max-width: $screen-lg-desktop) {
    
    .qb-body {

        .form-group-input {
            float: none;
        }
        .form-group-radio {
            margin-left: 0;
        }
        .qb-s4 .form-group-label {
            display: block;
            float: none;
        }
    }
}
@media (min-width: $screen-desktop) {

    .looking-tour {

        .date .form-control {
            max-width: 180px;
        }
        .small .date .form-control {
            max-width: 140px;
        }
    }

}

@media (max-width: $screen-desktop) {

}

@media (max-width: $screen-tablet) {

    .quick-booking {
        margin-top: 0;
    }
    .qb-title {
        text-align: center;
    }
    .qb-body {

        .form-group-input, .form-group-label {
            float: none;
        }
        .form-group-select {

            .form-control {
                margin: 0 0 15px;
            }
        }
        .form-group-radio {
            margin-left: 0;

            .radio {
                display: inline-block;
                margin: 0 10px 0 0;

                label {
                    padding-left: 0;
                }
                input {
                    position: relative;
                    margin-left: 0;
                }
            }
        }
        .qb-s1, .qb-s2 {

            .form-control {
                min-width: 1px;
            }
        }

    }
}
@media (max-width: $screen-phone) {

    .transfers-booking {

        h3 {
            text-align: center;
        }
    }
}
