@import url(//fonts.googleapis.com/css?family=Quicksand:400,700);


/* ==========================================================================
   Quicksand
   -
   http://www.google.com/fonts#UsePlace:use/Quicksand
   ========================================================================== */

@mixin qsand($font-weight:false, $font-style:false) {
    font-family: 'Quicksand';

    @if($font-weight) {
        font-weight: $font-weight;
    }
    @else {
        font-weight: 400;
    }
    @if($font-style) {
        font-style: $font-style;
    }
}

.qsand { // regular
    @include qsand();
}
.qsand-b { // bold
    @include qsand(700);
}

