$bundle: '/bundles/app';
$img-path: '#{$bundle}/images/';
$font-path: '#{$bundle}/fonts/';
$icon-font-path: $font-path;


//== Colors
$brand-primary: #cd0f3e;
$brand-info: #005e98;

//== Scaffolding
$text-color: #000;
$link-color: $text-color;
$link-hover-color: $link-color;

//== Typography
$font-family-sans-serif:  Arial, "Helvetica Neue", Helvetica, sans-serif;

$font-size-base: 14px;
$font-size-h1: floor(($font-size-base * 2.1)); // ~29px
$font-size-h2: floor(($font-size-base * 1.9)); // ~26px
$font-size-h3: ceil(($font-size-base * 1.6)); // ~23px
$font-size-h4: ceil(($font-size-base * 1.35)); // ~19px
$font-size-h5: ceil(($font-size-base * 1.1)); // ~16px
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px
$headings-color: $brand-info;


//== Components
$border-radius-base: 0;
$border-radius-large: 0;


//== Buttons
$btn-default-color: #fff;
$btn-default-bg: #979797;
$btn-default-border: #979797;

//== Forms
$input-bg: #fff;
$input-border: #ccc;
$input-color: #adadad;
$input-color-placeholder: #505050;
$input-group-addon-bg: $input-bg;
$input-group-addon-border-color: $input-bg;


//== Dropdowns
 

//== Form states and alerts
$state-info-text: $brand-info;



//== Container sizes
//$container-large-desktop: 1200px;

//== Media queries breakpoints
$screen-phone-xs: 320px;

//== Grid system
$grid-float-breakpoint: 992px;

//== Navbar
$navbar-default-link-color: $text-color;
$navbar-default-link-hover-bg: $brand-info;
$navbar-default-link-hover-color: #fff;
$navbar-default-link-active-bg: $brand-info;
$navbar-default-link-active-color: #fff;
$navbar-default-border: transparent;

$navbar-default-toggle-hover-bg: $brand-info;
$navbar-default-toggle-icon-bar-bg: $brand-info;
$navbar-default-toggle-border-color: $brand-info;


//== Tooltips
$tooltip-max-width: 300px;
$tooltip-arrow-width: 7px;
$tooltip-bg: #ff0000;
$tooltip-opacity: 1;

//== Modals
$modal-inner-padding: 20px;
$modal-lg: 760px;
//$modal-md: 600px;
//$modal-sm: 300px;
