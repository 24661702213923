// loading image
.loading-img {

    li {
        position: relative;
        left: -100%;

        &:first-child {
            left: 0;
        }
    }
}

.bx-wrapper {
    position: relative;

    .bx-controls-direction {

        a {
            display: inline-block;
            position: absolute;
            top: 50%;
            margin-top: -25.5px;
            outline: 0;
            text-indent: -9999px;
            z-index: 200;
        }

        .bx-prev {
            //@extend .icons-prev;
            left: 0;
        }
        .bx-next {
            //@extend .icons-next;
            right: 0;
        }
    }
    .bx-pager-item {
        display: inline-block;

        a {
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="); // IE8/9 FIX
            text-indent: -9999px;
            display: block;
            @include square(14px);
            margin: 0 5px;
            outline: 0;
            border-radius: 9999px;
            background-color: #fff;
            border: 1px solid #fff;

            &.active {
                background-color: $brand-info;
                border-color: $brand-info;
            }
        }
    }
    .bx-pager {
        position: absolute;
        z-index: 200;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        bottom: 8px;
    }

    @media (max-width: $screen-phone) {

        .bx-pager {
            right: 0;

            a {
                margin: 0 2px;
            }
        }
    }

}


/*
   Slider home
   ========================================================================== */

#slider-home {
    position: relative;

    .offer {
        height: 174px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -87px;
        padding: 23px 20px;
        text-align: center;
        background-color: #000;
        background-color: rgba(#000, .64);

        a, h2, h4 {
            color: #fff;
        }
        .btn {
            min-width: 200px;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        h2 {
            margin: 0;
            font-size: 25px;
            font-weight: bold;
            line-height: 1.3em;
        }
        h4 {
            font-size: 17px;
            margin: 0 0 25px;
        }        
    }

    @media (max-width: $screen-desktop) {
        margin-bottom: 15px;
    }

    @media (max-width: $screen-phone) {

        .bx-pager {
            position: static;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .bx-pager-item {
            display: inline-block;

            a {
                @include square(20px);
                background-color: #000;
                border: 1px solid #000;

                &.active {
                    background-color: $brand-info;
                    border-color: $brand-info;
                }
            }
        }
    }

    @media (max-width: 390px) {

        .offer {

            h2 {
                margin-top: 5px;
            }
            h4 {
                margin-bottom: 8px;
            }
            .btn {
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }

    }
}

